import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'opportunity',
    components: {},
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize() {
            if (this.activeUserWorkstation?.verificationStatus !== UserVerificationStatusEnum.VERIFIED) {
                this.$router.push({ name: 'dashboard' });
            }
        },
    },
});
